import React, { Component } from 'react';
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../../actions/generalActions";
import arrowLeft from '../../images/arrow-left.svg';
import calendar from '../../images/calendar.svg';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

const texts = [
    "dotnet add package Microsoft.PowerPlatform.Dataverse.Client",
    `using Microsoft.PowerPlatform.Dataverse.Client;
using System;

class Program
{
    static void Main(string[] args)
    {
        string clientId = "<Your Client ID>";
        string clientSecret = "<Your Client Secret>";
        string dataverseUrl = "https://<YourEnvironment>.crm.dynamics.com/";

        var connectionString = $@"
            AuthType=ClientSecret;
            Url={dataverseUrl};
            ClientId={clientId};
            ClientSecret={clientSecret};";

        using (var serviceClient = new ServiceClient(connectionString))
        {
            if (serviceClient.IsReady)
                Console.WriteLine("Connected to Dataverse successfully!");
            else
                Console.WriteLine($"Connection failed: {serviceClient.LastError}");
        }
    }
}
`,
];

const delay = ms => new Promise(res => setTimeout(res, ms));

const llaveizquierda = "{";
const llavederecha = "}";
const parentesisizquierdo = "(";
const menor = "<";
const mayor = ">";

class ConnectingDataversewithAzure extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codeCopied: 0
        }

        this.changeSection = this.changeSection.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Blog");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
    }

    async onClickCopy(codeCopied, isJson) {
        if (isJson)
            navigator.clipboard.writeText(JSON.stringify(texts[codeCopied - 1], null, 2));
        else
            navigator.clipboard.writeText(texts[codeCopied - 1]);

        this.setState({ codeCopied: codeCopied });

        await delay(2000);

        this.setState({ codeCopied: 0 });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Securely Connecting to Dataverse with an Azure Application Registration</title>
                    <meta name="description" content="Learn how to configure a secure connection to Dataverse by setting up API permissions and roles using an Azure-registered application." />
                    <meta name="keywords" content="ollama, .NET, C#, AI, NLP, console application, language model, artificial intelligence" />
                    <meta property="og:title" content="Securely Connecting to Dataverse with an Azure Registered Application" />
                    <meta property="og:description" content="Learn how to configure a secure connection to Dataverse by setting up API permissions and roles using an Azure-registered application." />
                    <meta property="og:type" content="article" />
                    <meta property="article:published_time" content="2025-01-27" />
                </Helmet>
                <section class="article-section">
                    <div className='article-container'>
                        <div className='article-header'>
                            <a href='/blog'>
                                <div className='back-button-container'>
                                    <img src={arrowLeft} />
                                    <label>Back to Articles</label>
                                </div>
                            </a>
                        </div>
                        <div className='article'>
                            <h1>Securely Connecting to Dataverse with an Azure Application Registration</h1>
                            <div className='author-date-container'>
                                <div className='general-container'>
                                    <img src={calendar} />
                                    <label>January 27, 2025</label>
                                </div>
                            </div>
                            <div className='article-div'>
                                <p>
                                    In this guide, we’ll walk you through the steps to set up a secure connection to Microsoft Dataverse using an Azure-registered application.
                                    This includes configuring API permissions in Azure, setting up the necessary roles in Dataverse, and authenticating the connection from your application.
                                </p>
                                <h2>Prerequisites</h2>
                                <div className='step'>
                                    <p className='mb-05'>
                                        Before you begin, ensure you have the following:
                                    </p>
                                    <ul class="custom-bullets">
                                        <li>Access to the Azure portal with sufficient permissions to register applications.</li>
                                        <li>A Microsoft Power Platform or Dynamics 365 environment with Dataverse enabled.</li>
                                        <li>An editor or IDE to write and test code (e.g., Visual Studio, Visual Studio Code).</li>
                                    </ul>
                                </div>
                                <h3>Step 1: Register the Application in Azure</h3>
                                <div className='step'>
                                    <ol className='custom-bullets numbers'>
                                        <li className='two'>
                                            <p className='list-title'>Go to the Azure Portal.</p>
                                            <p>Navigate to <a href='https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview' target='_blank'>Microsoft Entra ID</a>.</p>
                                        </li>
                                        <li className='two'>
                                            <p className='list-title'>Register a New Application</p>
                                            <p><span className='bull'>•</span> In the left-hand menu, select <span className='fw600'>App registrations</span> {mayor} <span className='fw600'>New registration</span>.</p>
                                            <p><span className='bull'>•</span> Provide a name for your application (e.g., <span className='pd'>DataverseSecureApp</span>).</p>
                                            <p><span className='bull'>•</span> Select Accounts in this organizational directory only.</p>
                                            <p><span className='bull'>•</span> Add a redirect URI (optional, based on your application's authentication flow).</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>Register</span>.</p>
                                        </li>
                                        <li className='two'>
                                            <p className='list-title'>Note the Application (client) ID</p>
                                            <p>This will be needed later for authentication.</p>
                                        </li>
                                        <li className='two'>
                                            <p className='list-title'>Add API Permissions</p>
                                            <p><span className='bull'>•</span> Go to <span className='fw600'>API permissions</span> in the application settings.</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>Add a permission</span> {mayor} <span className='fw600'>Microsoft APIs</span> {mayor} <span className='fw600'>Dynamics CRM</span>.</p>
                                            <p><span className='bull'>•</span> Select <span className='fw600'>Delegated permissions</span> and check <span className='pd'>user_impersonation</span>.</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>Grant admin consent for [your organization]</span> to apply the permissions at the tenant level.</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>Add permissions</span>.</p>
                                        </li>
                                        <li className='two'>
                                            <p className='list-title'>Generate a Client Secret</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>Navigate to </span> {mayor} <span className='fw600'>Certificates & secrets</span> {mayor} <span className='fw600'>New client secret</span>.</p>
                                            <p><span className='bull'>•</span> Add a description (e.g., <span className='fw600'>DataverseClientSecret</span>) and set an expiration period.</p>
                                            <p><span className='bull'>•</span> Save the generated secret; you’ll need it later.</p>
                                        </li>
                                    </ol>
                                    <p className='mt-20 mb-25'>For better clarity, here is a video demonstration outlining the complete configuration process.</p>
                                    <div className='video-container'>
                                        <ReactPlayer
                                            url='../../azure-app-registration.mp4'
                                            poster="../../portada5.png"
                                            controls={true}
                                            width={window.innerWidth >= 767 ? '85%' : '100%'}
                                            height='auto'
                                            style={{
                                                borderRadius: '6px',
                                                background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                                            }}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        poster: '../../portada5.png'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <h3>Step 2: Configure the Application in Dataverse</h3>
                                <div className='step'>
                                    <ol className='custom-bullets numbers'>
                                        <li className='two'>
                                            <p className='list-title'>Access the Power Platform Admin Center</p>
                                            <p>Navigate to <a href='https://admin.powerplatform.microsoft.com' target='_blank'>Power Platform Admin Center</a>.</p>
                                        </li>
                                        <li className='two'>
                                            <p className='list-title'>Assign Security Roles to the Application</p>
                                            <p><span className='bull'>•</span> In your environment, go to <span className='fw600'>Access</span> {mayor} <span className='fw600'>Users + See all</span>.</p>
                                            <p><span className='bull'>•</span> Then click on <span className='fw600'>app users list</span>.</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>New app user</span>.</p>
                                            <p><span className='bull'>•</span> In the right-hand menu, click <span className='fw600'>Add an app</span> and search for the application created in <span className='fw600'>Step 1</span> <span className='pd'>DataverseSecureApp</span>.</p>
                                            <p><span className='bull'>•</span> Enter your <span className='fw600'>Business unit</span>.</p>
                                            <p><span className='bull'>•</span> Assign appropriate security roles to the application (e.g., <span className='pd'>System Administrator</span>).</p>
                                            <p><span className='bull'>•</span> Click <span className='fw600'>Create</span>.</p>
                                        </li>
                                    </ol>
                                    <p className='mt-20 mb-25'>To make the implementation clearer, here is a demonstration video showcasing the detailed configuration steps.</p>
                                    <div className='video-container'>
                                        <ReactPlayer
                                            url='../../configure-app-in-dataverse.mp4'
                                            poster="../../portada6.png"
                                            controls={true}
                                            width={window.innerWidth >= 767 ? '85%' : '100%'}
                                            height='auto'
                                            style={{
                                                borderRadius: '6px',
                                                background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                                            }}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        poster: '../../portada6.png'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <h3>Step 3: Authenticate and Connect from Your Application</h3>
                                <div className='step'>
                                    <p>Here’s how to connect securely from your application:</p>
                                    <p><span className='bold'>Install Required Libraries</span></p>
                                    <p>Install the <span>Microsoft.PowerPlatform.Dataverse.Client</span> NuGet package:</p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>bash</label>
                                            <Button onClick={() => this.onClickCopy(1, false)}>
                                                <Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 1 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p>dotnet add package Microsoft.PowerPlatform.Dataverse.Client</p>
                                        </div>
                                    </div>
                                    <p><span className='bold'>Write Connection Code</span></p>
                                    <p>Use the following example to establish a secure connection:</p>
                                    <div className='bash'>
                                        <div className='bash-header'>
                                            <label>csharp</label>
                                            <Button onClick={() => this.onClickCopy(2, false)}>
                                                <Icon className={this.state.codeCopied == 2 ? 'check' : ''} name={this.state.codeCopied == 2 ? 'check' : 'copy outline'} />
                                                <p>{this.state.codeCopied == 2 ? ' Copied!' : 'Copy'}</p>
                                            </Button>
                                        </div>
                                        <div className='bash-body'>
                                            <p><span className='blue'>using</span> Microsoft.PowerPlatform.Dataverse.Client;</p>
                                            <p><span className='blue'>using</span> System;</p>
                                            <br></br>
                                            <p><span className='blue'>class </span> <span className='red'>Program</span></p>
                                            <p>{llaveizquierda}</p>
                                            <p className='one-space'><span className='blue'>static void</span> <span className='red'>Main</span>{parentesisizquierdo}<span className='orange'>string</span>[] args{llavederecha}</p>
                                            <p className='one-space'>{llaveizquierda}</p>
                                            <p className='two-spaces'><span className='orange'>string</span> clientId = <span className='green'>"{menor}Your Client ID{mayor}"</span>;</p>
                                            <p className='two-spaces'><span className='orange'>string</span> clientSecret = <span className='green'>"{menor}Your Client Secret{mayor}"</span>;</p>
                                            <p className='two-spaces'><span className='orange'>string</span> dataverseUrl = <span className='green'>"https://{menor}YourEnvironment{mayor}.crm.dynamics.com/"</span>;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='blue'>var</span> connectionString = <span className='green'>{'$@"'}</span></p>
                                            <p className='three-spaces'><span className='green'>AuthType=ClientSecret;</span></p>
                                            <p className='three-spaces'><span className='green'>{"Url={dataverseUrl};"}</span></p>
                                            <p className='three-spaces'><span className='green'>{"ClientId={clientId};"}</span></p>
                                            <p className='three-spaces'><span className='green'>{'ClientSecret={clientSecret};"'}</span>;</p>
                                            <br></br>
                                            <p className='two-spaces'><span className='blue'>using</span> (<span className='blue'>var</span> serviceClient = <span className='blue'>new</span> ServiceClient(connectionString))</p>
                                            <p className='two-spaces'>{llaveizquierda}</p>
                                            <p className='three-spaces'><span className='blue'>if</span> (serviceClient.IsReady)</p>
                                            <p className='four-spaces'>Console.WriteLine(<span className='green'>"Connected to Dataverse successfully!"</span>);</p>
                                            <p className='three-spaces'><span className='blue'>else</span></p>
                                            <p className='four-spaces'>Console.WriteLine(<span className='green'>{'$"Connection failed: {serviceClient.LastError}"'}</span>);</p>
                                            <p className='two-spaces'>{llavederecha}</p>
                                            
                                            <p className='one-space'>{llavederecha}</p>
                                            <p>{llavederecha}</p>
                                        </div>
                                    </div>
                                    <p><span className='bold'>Test the Connection</span></p>
                                    <p>Run your application and ensure it connects successfully to Dataverse.</p>
                                </div>
                                <h3>Hope This Helps</h3>
                                <div className='last-step'>
                                    <p>
                                        We hope this guide has been helpful in setting up a secure connection to Dataverse using an Azure-registered application. 
                                        You’re now ready to leverage the integration and security capabilities this configuration offers. Best of luck with your project!
                                    </p>
                                </div>
                            </div>
                            <div className='footer'>
                                <span>Azure</span>
                                <span>Dataverse</span>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectingDataversewithAzure);