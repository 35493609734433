import React, { Component } from 'react';
import Footer from '../Footer';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
  changeCurrentSection,
} from "../../actions/generalActions";
import GettingStartedWithOllama from './Articles/GettingStartedWithOllama';
import HowToBuildAChatAppUsingOllama from './Articles/HowToBuildAChatAppUsingOllama';
import OllamaPlusNETScraper from './Articles/OllamaPlusNETScraper';
import BoltV0 from './Articles/BoltV0';
import OllamaDataverseOverview from './Articles/OllamaDataverseOverview';
import OllamaDataverseImplementation from './Articles/OllamaDataverseImplementation';
import IntegratingMilvus from './Articles/IntegratingMilvus';
import ConnectingDataversewithAzure from './Articles/ConnectingDataversewithAzure';

const routes = [
  { hash: "#install-and-run-ollama-net", component: <GettingStartedWithOllama /> },
  { hash: "#build-chat-app-using-ollama", component: <HowToBuildAChatAppUsingOllama /> },
  { hash: "#extract-and-analyze-web-content-using-ollama", component: <OllamaPlusNETScraper /> },
  { hash: "#bolt.new-vs-v0", component: <BoltV0 /> },
  { hash: "#ollama-with-dataverse-overview", component: <OllamaDataverseOverview /> },
  { hash: "#ollama-with-dataverse-technical-implementation", component: <OllamaDataverseImplementation /> },
  { hash: "#milvus-dataverse-integration", component: <IntegratingMilvus /> },
  { hash: "#secure-connection-dataverse-azure", component: <ConnectingDataversewithAzure/>}
];

class BlogArticle extends Component {
  constructor(props) {
    super(props);

    this.changeSection = this.changeSection.bind(this);
  }

  changeSection() {
    this.props.changeCurrentSection("Blog");
    if (this.props.cookieUp)
      ReactGA4.send({ hitType: "pageview", title: "Blog", page: '/Blog' });
  }

  render() {
    let hash = window.location.hash;

    if (!hash)
      window.location.pathname = "/blog";

    const route = routes.find(route => route.hash === hash);

    if (!route){
      window.location.hash = "";
      window.location.pathname = "/blog";
    }

    return (
      <div className='blog-global-screen' onMouseOver={this.props.currentSection != "Blog" ? this.changeSection : () => { }}>
        {route.component}
        <Footer
          language={this.props.language}
          currentSection={this.props.currentSection}
          changeCurrentSection={this.props.changeCurrentSection}
          cookieUp={this.props.cookieUp}
        />
      </div>
    );
  }
}

const mapStateToProps = (value) => {
  return {
    language: value.general.language,

    currentSection: value.general.currentSection,

    cookieUp: value.general.cookieUp
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogArticle);