import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from './images/logo-coowise.svg'
import { connect } from "react-redux";
import {
    changeLanguage,
    changeCurrentSection,
    subscribeBlog
} from "../actions/generalActions";
import spainFlag from './images/Flag_Spain.svg';
import united_Kingdom from './images/Flag_United_Kingdom.svg';
import ReactGA4 from 'react-ga4';
import { Link } from 'react-router-dom';
import email from './images/email.svg';
import NewsletterModal from './modals/NewsletterModal';

class NavbarWeb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            legalActiveItem: '',

            newsletterModalFlag: false,
            email: '',
            errorMessage: '' 
        }

        this.changeLanguage = this.changeLanguage.bind(this);
        this.onClickLegalLink = this.onClickLegalLink.bind(this);
        this.ref = React.createRef();
        this.changeNewsletterModalFlag = this.changeNewsletterModalFlag.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
    }

    changeNewsletterModalFlag(subscribe){
        let newsletterModalFlag = JSON.parse(window.sessionStorage.getItem("newsletterModalFlag"));
        
        if(subscribe === true){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let flag = re.test(String(this.state.email).toLowerCase());

            if (this.state.email !== "" && flag)
            {
                this.props.subscribeBlog(this.state.email);
                window.sessionStorage.setItem("newsletterModalFlag", !newsletterModalFlag);
                this.setState({ newsletterModalFlag: !this.state.newsletterModalFlag });
            }
            else{
                if(this.state.email == "")
                    this.setState({ errorMessage: "This field cannot be empty." });
                else
                    this.setState({ errorMessage: "Your E-mail must be valid." });
            }
        }
        else{
            window.sessionStorage.setItem("newsletterModalFlag", !newsletterModalFlag);
            this.setState({ newsletterModalFlag: !this.state.newsletterModalFlag, errorMessage: "" });
        }
    }

    onChangeEmail(e){
        this.setState({ email: e.target.value, errorMessage: "" });
    }

    onClickLink(id, section) {
        if (this.props.currentSection != section) {
            this.props.changeCurrentSection(section);
            if (this.props.cookieUp)
                ReactGA4.send({ hitType: "pageview", title: section, page: ('/' + section) });
        }

        let element = document.getElementById(id);
        if (element != null) {
            let windowWidth = window.innerWidth;
            if (windowWidth > 991)
                element.scrollIntoView();
            else {
                element.scrollIntoView();
                window.scrollBy(0, -50);
            }
        }

        let collapse = document.getElementById("responsive-navbar-nav")

        if (collapse != null && collapse.className === "navbar-collapse collapse show" && this.ref.current != null)
            this.ref.current.click();
    }

    changeLanguage(language) {
        if (this.props.cookieUp)
            ReactGA4.gtag('event', 'click', {
                'event_category': 'User',
                'event_label': 'Click on Language (' + language + ')',
                'value': 'Click on Language (' + language + ')'
            });

        this.props.changeLanguage(language);
    }

    onClickLegalLink(legalActiveItem) {
        this.setState({ legalActiveItem: legalActiveItem });

        let element = document.getElementById("privacy-section");
        if (element != null)
            element.scrollIntoView({ behavior: "smooth" });

        let collapse = document.getElementById("responsive-navbar-nav")

        if (collapse != null && collapse.className === "navbar-collapse collapse show" && this.ref.current != null)
            this.ref.current.click();
    }

    componentDidMount() {
        if (window.location.pathname == "/cookies-policy")
            this.setState({ legalActiveItem: "/cookies-policy" });
        else
            if (window.location.pathname == "/privacy-policy")
                this.setState({ legalActiveItem: "/privacy-policy" });
    }

    render() {
        let pathname = window.location.pathname;

        let newsletterModalFlag = window.sessionStorage.getItem("newsletterModalFlag");

        if(newsletterModalFlag == null){
            newsletterModalFlag = true;
            window.sessionStorage.setItem("newsletterModalFlag", true);
        }
        else
            newsletterModalFlag = JSON.parse(newsletterModalFlag);

        let blogClass = pathname == "/blog" || pathname == "/article" ? " blog" : "";

        let newsletterEmail = window.localStorage.getItem("newsletterEmail");

        return (
            <>
                <Navbar collapseOnSelect expand="lg" className="navCoowise navbar-static-top">
                    <Navbar.Brand
                        onClick=
                        {
                            pathname != "/cookies-policy" && pathname != "/privacy-policy" && pathname != "/blog" && pathname != "/article"
                                ? () => this.onClickLink("header")
                                : () => { window.location.pathname = "/" }
                        }
                    >
                        <img className="cursor" src={logo} alt="logo-coowise" />
                        {
                            pathname == "/blog" || pathname == "/article" ? <h1 className='blog-title'>Blog</h1> : <></>
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" id="navbar-toggle" ref={this.ref} />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        {
                            <Nav className={"mr-auto nav-coowise" +  blogClass}>
                            {
                                pathname != "/cookies-policy" && pathname != "/privacy-policy" && pathname != "/blog" && pathname != "/article"
                                    ?
                                    <>
                                        <Nav.Link data-toggle="collapse" onClick={() => this.onClickLink("header", "Home")}>{this.props.language == "ES" ? "INICIO" : "HOME"}</Nav.Link>
                                        <Nav.Link onClick={() => this.onClickLink("servicios", "Services")}>{this.props.language == "ES" ? "SERVICIOS" : "SERVICES"}</Nav.Link>
                                        <Nav.Link onClick={() => this.onClickLink("soluciones", "Solutions")}>{this.props.language == "ES" ? "SOLUCIONES" : "SOLUTIONS"}</Nav.Link>
                                        <Nav.Link onClick={() => this.onClickLink("contacto", "Contact")}>{this.props.language == "ES" ? "CONTACTO" : "CONTACT"}</Nav.Link>
                                        <Nav.Link href={window.location.origin + "/blog"} target="_blank">{this.props.language == "ES" ? "BLOG" : "BLOG"}</Nav.Link>
                                    </>
                                    : pathname == "/cookies-policy" || pathname == "/privacy-policy"
                                        ?
                                        <>
                                            <Link
                                                to="/privacy-policy"
                                                onClick={() => this.onClickLegalLink("/privacy-policy")}
                                                className={"nav-link legal" + (this.state.legalActiveItem == "/privacy-policy" ? " selected" : "")}
                                            >
                                                {this.props.language == "ES" ? "POLÍTICA DE PRIVACIDAD" : "PRIVACY POLICY"}
                                            </Link>
                                            <Link
                                                to="/cookies-policy"
                                                onClick={() => this.onClickLegalLink("/cookies-policy")}
                                                className={"nav-link legal" + (this.state.legalActiveItem == "/cookies-policy" ? " selected" : "")}
                                            >
                                                {this.props.language == "ES" ? "POLÍTICA DE COOKIES" : "COOKIE POLICY"}
                                            </Link>
                                        </>
                                        :
                                        <>
                                            <div className='transparent-link'></div>
                                        </>
                            }
                            </Nav>
                        }

                        {
                            pathname == "/blog" || pathname == "/article"
                                ?
                                <div className='join-newsletter-container' onClick={() => this.changeNewsletterModalFlag(false)}>
                                    <img src={email} />
                                    Join Newsletter
                                </div>
                                : null
                        }
                        {
                            pathname != "/blog" && pathname != "/article"
                                ?
                                <NavDropdown
                                    className="language"
                                    title={this.props.language == "ES" ? "Idioma" : "Language"}
                                    onClick={() => {
                                        if (this.props.cookieUp)
                                            ReactGA4.send({ hitType: "pageview", title: "Language", page: '/Language' });
                                    }}
                                >
                                    <NavDropdown.Item onClick={() => this.changeLanguage("EN")}>EN <img class="imgNav uk" src={united_Kingdom} /></NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => this.changeLanguage("ES")}>ES <img class="imgNav" src={spainFlag} /></NavDropdown.Item>
                                </NavDropdown>
                                : <></>
                        }

                    </Navbar.Collapse>
                </Navbar>
                {
                    newsletterEmail == null ?
                        pathname == "/blog" || pathname == "/article" ? 
                            <NewsletterModal 
                                newsletterModalFlag={newsletterModalFlag} 
                                changeNewsletterModalFlag={this.changeNewsletterModalFlag}
                                email={this.state.email}
                                errorMessage={this.state.errorMessage}
                                onChangeEmail={this.onChangeEmail}
                            />
                            : null
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language)),
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection)),
        subscribeBlog: (email) => dispatch(subscribeBlog(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarWeb);